import React, { useState, useEffect } from "react";

import logo from './tyler-logo.svg';
import './App.css';
import banner1 from './tyler.png';
import aboutImage from './tyler-apu.jpg';
import musicBG from './music-bg.jpg';
import podcast from './podcast.jpg';
import yetiAlbum1 from './yeti-yetiville.jpg';
import yetiAlbum2 from './yeti-somewhere-in-the-end.jpg';
import yetiAlbum3 from './yeti-live-canopy-club.jpg';
import alplmExhibits from './alplm-exhibits.jpg';
import blackhawk from './blackhawk.jpg';
import chestnut from './chestnut.jpg';
import designerLandscapes from './designer-landscapes.jpg';
import illinoisMovers from './illinois-movers.jpg';
import prn from './prn.jpg';
import psb from './prairie-state-bank.jpg';
import primo from './primo.jpg';
import bos from './bos.jpg';
import shg from './shg.jpg';
import designHouse from './design-house.jpg';
import usElectric from './us-electric.jpg';
import erfc from './erfc.jpg';
import hfrrf from './hfrrf.jpg';
import lpf from './lpf.jpg';
import flyspi from './flyspi.jpg';
import llcu from './llcu.jpg';
import hcu from './hcu.jpg';
import lrsretirement from './lrsretirement.jpg';
import sangamonceo from './sangamonceo.jpg';
import aprs from './aprs.jpg';
import mjkellner from './mjkellner.jpg';
import lrs from './lrs.jpg';
import msfw from './msfw.jpg';
import apu from './apu.jpg';
import cnbil from './cnbil.jpg';
import toi from './toi.jpg';
import ninthJudicial from './9judicial.jpg';

function App() {
  
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const offset = 0; // Adjust offset as needed
      const sectionPosition =
        section.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = sectionPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");

  const openLightbox = () => {
    setVideoSrc("https://www.youtube.com/embed/KO76w6fp4vI?autoplay=1");
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
    setVideoSrc(""); // Clear the video URL to stop playback
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape" && isLightboxOpen) {
        closeLightbox();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener when component unmounts or lightbox state changes
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [isLightboxOpen]);  

  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const feedUrl = 'https://unplugged.allpunkedup.com/feed';
  const targetAuthor = '';

  useEffect(() => {
    async function fetchRSSFeed() {
      try {
        const corsProxy = 'https://api.allorigins.win/raw?url=';
        const response = await fetch(corsProxy + encodeURIComponent(feedUrl));

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const text = await response.text();
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, 'text/xml');

        const items = xmlDoc.querySelectorAll('item');
        const parsedPosts = Array.from(items).map(item => {
          const description = item.querySelector('description')?.textContent || '';
          const mediaContent = item.querySelector('enclosure')?.getAttribute('url');
          const imageUrl = mediaContent || (description.match(/<img[^>]+src="([^">]+)"/)?.[1] || null);
          const author = item.querySelector('dc\\:creator')?.textContent || '';

          return {
            title: item.querySelector('title')?.textContent || 'Untitled',
            link: item.querySelector('link')?.textContent || '#',
            description: description.replace(/<[^>]+>/g, ''), // Remove HTML tags from description
            pubDate: new Date(item.querySelector('pubDate')?.textContent || '').toLocaleDateString(),
            imageUrl: imageUrl,
            author: author,
          };
        });

        // Filter by specific author
        const filteredPosts = parsedPosts.filter(post => post.author === targetAuthor);

        // Limit amount of posts
        setPosts(filteredPosts.slice(0, 6));
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchRSSFeed();
  }, []);

  return (
    <div className="site-wrapper" id="home">

      {/* Header */}
      <header className="header">
        
        {/* Accessibility Links */}
        <a className="skipcontent" href="#main" tabIndex="0">Skip to Main Content</a>
        <a className="skipcontent" href="#navigation" tabIndex="0">Skip to Main Navigation</a>
        {/* <a className="skipcontent" href="#footer" tabIndex="0">Skip to Footer</a> */}

        <div className="header__inner">
          <a className="logo" href="#home" onClick={(e) => { e.preventDefault(); scrollToSection('home'); }}title="Go to Tyler Winters homepage">
            <img src={logo} className="" alt="Tyler Winters" />
          </a>
          <nav className="navigation" id="navigation">
            <ul className="navigation__list">
              <li className="navigation__list-item"><a className="navigation__list-item-link" href="#about" onClick={(e) => { e.preventDefault(); scrollToSection('about'); }}>About</a></li>
              <li className="navigation__list-item"><a className="navigation__list-item-link" href="#resume" onClick={(e) => { e.preventDefault(); scrollToSection('resume'); }}>Resumé</a></li>
              <li className="navigation__list-item"><a className="navigation__list-item-link" href="#portfolio" onClick={(e) => { e.preventDefault(); scrollToSection('portfolio'); }}>Portfolio</a></li>
              <li className="navigation__list-item"><a className="navigation__list-item-link" href="#music" onClick={(e) => { e.preventDefault(); scrollToSection('music'); }}>Music</a></li>
              <li className="navigation__list-item"><a className="navigation__list-item-link" href="#podcast" onClick={(e) => { e.preventDefault(); scrollToSection('podcast'); }}>Podcast</a></li>
              <li className="navigation__list-item"><a className="navigation__list-item-link" href="#blog" onClick={(e) => { e.preventDefault(); scrollToSection('blog'); }}>Blog</a></li>
              <li className="navigation__list-item"><a className="navigation__list-item-link" href="#contact" onClick={(e) => { e.preventDefault(); scrollToSection('contact'); }} target="_blank">Contact</a></li>
              {/* <li className="navigation__list-item"><a className="button button--white button--dark-border" target="_blank" href="#contact">Contact</a></li> */}
            </ul>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main id="main">

        {/* Banner */}
        <div className="homepage-banner">
          <div className="homepage-banner__image-container">
            <div className="homepage-banner__image" style={{ backgroundImage: `url(${banner1})` }}></div>
          </div>

          <div className="homepage-banner__text-container">
            <div className="homepage-banner__text">
              <h1>
                <span>Tyler</span> Winters
              </h1>
              <div className="homepage-banner__subtext">
                <em>Front-End Developer, Accessibility Specialist, Musician, & Podcaster</em>
              </div>
            </div>
          </div>
        </div>

        {/* About */}
        <section className="section" id="about">
          <div className="section__container section__container-group section__container-group--2 section__container-group--align-center">
            <div className="section__container-item">
              <img className="section__container-item-image section__container-item-image--circle" src={aboutImage} alt="" />
            </div>
            <div className="section__container-item">
              <div className="section__container-item-tag"><span>About Me</span></div>
              <h2 className="section__container-item-title">One super awesome front-end developer from the Chatham/Springfield, IL area</h2>
              <p>I'm a front-end web developer and digital accessibility specialist, passionate and dedicated to crafting exceptional user experiences. With over 10 years of professional experience, I've honed the skills and knowledge necessary to make any project a success. From creating accessible and innovative websites and apps to building dynamic portfolios and blogs, I enjoy every step of the process—from discussion and collaboration to development and implementation. For what it's worth, my creativity and interests extend beyond code as I enjoy showcasing my love for music whether that's work from my old band or sharing engaging stories, interviews, and music news on my podcast, <em><a href="https://www.theunpluggedpodcast.com/" target="_blank">Unplugged with Tyler Winters and John Pearman</a></em>.</p>
            </div>
          </div>
        </section>

        {/* Resume */}
        <section className="section" id="resume">
          <div className="section__container section__container-group section__container--contained">
            <div className="section__container-item">
              <div className="section__container-item-tag"><span>Resumé</span></div>
              <h2 className="section__container-item-title">My Professional Experiences</h2>
              <p>I am a results-driven Front-End Web Developer with a passion for creating accessible systems and enhancing user experiences. With expertise in designing intuitive and inclusive digital solutions across diverse platforms, I excel at leveraging data-driven insights to improve website performance and user satisfaction. I have a proven track record of collaborating effectively with cross-functional teams, ensuring the seamless integration of design, development, and testing processes.</p>
              <p>Below is a timeline highlighting key milestones and roles throughout my career, showcasing my growth and expertise in web development and accessibility.</p>
              <div className="resume">
                <div className="resume__experience">
                  <div className="resume__experience-company">
                    <img src={msfw} alt=""/>
                    <div className="resume__experience-company-name">MSF&W Consulting</div>
                  </div>
                  <div className="resume__divider"></div>
                  <div className="resume__experience-info">                 
                    <h3 className="resume__experience-title">Accessibility Specialist & Front-end Developer</h3>
                    {/* <div className="resume__experience-timeframe">8 years & 9 months</div> */}
                    <div className="resume__experience-dates">August 2024 - Present</div>
                    <div className="resume__experience-location">Springfield, IL</div>
                    <p className="resume__experience-details">I specialize in testing websites, web applications, and mobile apps for compliance with WCAG, Section 508, and other accessibility standards, leveraging tools like WAVE, ARC Toolkit, and assistive technologies such as JAWS. My work involves identifying accessibility issues through code reviews, functional testing, and wireframe analysis, and providing detailed findings and recommendations, often including screenshots and code snippets, via written reports or tracking systems like JIRA.</p>
                    <p>I assist developers during remediation by offering technical guidance and conducting quality assurance re-tests to ensure compliance. Additionally, I enhance the accessibility of digital documents, performing comprehensive PDF remediation and improving PowerPoint, Word, and Excel documents for screen reader compatibility. My role also involves strategizing accessibility approaches, prioritizing tasks, and collaborating with clients and developers to ensure inclusive, accessible digital experiences.</p>
                    <p>In my role as a front-end developer, I have designed and implemented custom widgets that streamline processes and improve efficiency for accessibility audits and reporting. Additionally, I'm responsible for building and maintaining the new MSF&W website, ensuring it meets both functional and accessibility standards while delivering a seamless user experience.</p>
                  </div>
                </div>
                <div className="resume__experience">
                  <div className="resume__experience-company">
                    <img src={lrs} alt=""/>                 
                    <div className="resume__experience-company-name">Levi, Ray & Shoup, Inc. (LRS)</div>
                  </div>
                  <div className="resume__divider"></div>
                  <div className="resume__experience-info">                 
                    <h3 className="resume__experience-title">Web Developer</h3>
                    <div className="resume__experience-timeframe">8 years & 9 months</div>
                    <div className="resume__experience-dates">Dec 2015 - August 2024</div>
                    <div className="resume__experience-location">Springfield, IL</div>
                    <p className="resume__experience-details">Collaborates with the project and LRS Marketing teams while assisting in the support and maintenance of websites for LRS and LRS clients. Responsible for constructing, complex websites, using modern front-end development technology like HTML, SASS (CSS) and JavaScript. Integrating professional graphic design with client content and custom-built applications. Consults on usability, accessibility (WCAG 2.1), browser-compatibility, and quality in website construction.</p>
                    <p>Responsible for helping to maintain and update websites for existing LRS customers. Constructs new, complex websites, using HTML and cascading style sheets. Integrates professional graphic design with client content and custom-built applications. Consults on usability, accessibility, browser-compatibility, and quality in website construction.</p>
                  </div>
                </div>
                <div className="resume__experience">
                  <div className="resume__experience-company">
                    <img src={apu} alt=""/>                            
                    <div className="resume__experience-company-name">All Punked Up LLC</div>
                  </div>
                  <div className="resume__divider"></div>
                  <div className="resume__experience-info">                 
                    <h3 className="resume__experience-title">Founder, Web Developer, & Podcaster</h3>
                    {/* <div className="resume__experience-timeframe">8 years & 9 months</div> */}
                    <div className="resume__experience-dates">May 2016 - Present</div>
                    <div className="resume__experience-location">Chatham, IL</div>
                    <p className="resume__experience-details">As the founder and creative director of All Punked Up, I've built a platform dedicated to supporting the music scene through engaging content and strategic storytelling. Over the years, I've developed and executed content strategies for music news, artist features, and industry insights, while producing and co-hosting <em>Unplugged with Tyler Winters and John Pearman</em>, a music news and artist interview podcast with over 200 episodes.</p>
                    <p>In this role, I've collaborated with PR professionals and music industry leaders to promote both emerging and established artists, fostering strong partnerships and a trusted reputation. Through social media, SEO, and email marketing, I've successfully grown the brand's reach and influence, connecting fans and artists while sharing compelling stories from the music world.</p>
                  </div>
                </div>
                <div className="resume__experience">
                  <div className="resume__experience-company">
                    <img src={logo} alt=""/>                            
                    <div className="resume__experience-company-name">tylerwinters.com / Small Shop Websites</div>
                  </div>
                  <div className="resume__divider"></div>
                  <div className="resume__experience-info">                 
                    <h3 className="resume__experience-title">Owner & Web Developer</h3>
                    {/* <div className="resume__experience-timeframe">8 years & 9 months</div> */}
                    <div className="resume__experience-dates">March 2012 - Present</div>
                    <div className="resume__experience-location">Chatham, IL</div>
                    <p className="resume__experience-details">Plans, creates, launches and manages business websites with the use of visual mockups, graphic design, Photoshop, WordPress platform and using HTML and CSS languages. Provides strong customer service by helping the customer feel at ease, as well as, staying in contact with the customer throughout the creation of their website. Uses cold calling as a way to engage new and prospective clients.</p>
                  </div>
                </div>
              </div>
              <p style={{ textAlign: 'center' }}><a href="https://www.linkedin.com/in/tyler-winters/" className="button button--white button--dark-border" target="_blank">Let's Connect</a></p>
            </div>
          </div>
        </section>

        {/* Portfolio */}
        <section className="section" id="portfolio">
          <div className="section__container">
            <div className="section__container-item">
              <div className="section__container-item-tag"><span>Portfolio</span></div>
              <h2 className="section__container-item-title">My work</h2>
              <p>Over the years, I've had the privilege of working on a diverse range of custom website projects, blending creativity with technical expertise to deliver accessible and visually engaging digital experiences. Each project tells a story of collaboration, attention to detail, and a commitment to excellence. Below, you'll find a curated selection of my work that highlights my skills and approach to front-end development and digital accessibility.</p>
              <ul className="portfolio-grid">
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={alplmExhibits} alt="" />
                  <a href="https://online-exhibits.presidentlincoln.illinois.gov/the-state-of-the-sound/exhibit" className="portfolio-grid__item-link" target="_blank">
                    <span>Abraham Lincoln Presidential Library and Museum Exhibits</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={ninthJudicial} alt="" />
                  <a href="https://www.9thjudicial.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Ninth Judicial Circuit Court of Illinois</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={bos} alt="" />
                  <a href="https://www.bankwithbos.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Bank of Springfield</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={llcu} alt="" />
                  <a href="https://www.llcu.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Lincoln Land Credit Union</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={psb} alt="" />
                  <a href="https://www.psbank.net/" className="portfolio-grid__item-link" target="_blank">
                    <span>Prairie State Bank & Trust</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={blackhawk} alt="" />
                  <a href="https://choosethechief.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Blackhawk Bank & Trust</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={cnbil} alt="" />
                  <a href="https://www.cnbil.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>CNB Bank & Trust</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={hcu} alt="" />
                  <a href="https://www.hcu.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Heartland Credit Union</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={primo} alt="" />
                  <a href="https://www.primodesigns.net/" className="portfolio-grid__item-link" target="_blank">
                    <span>Primo Designs</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={flyspi} alt="" />
                  <a href="https://www.flyspi.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Abraham Lincoln Capital Airport at Springfield</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={designerLandscapes} alt="" />
                  <a href="https://www.designer-landscapes.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Designer Landscapes</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={mjkellner} alt="" />
                  <a href="https://www.mjkellner.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>MJ Kellner</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={shg} alt="" />
                  <a href="https://www.shg.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Sacred Heart Griffin</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={illinoisMovers} alt="" />
                  <a href="https://www.imawa.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Illinois Movers & Warehouse Association</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={aprs} alt="" />
                  <a href="https://www.ausprs.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Austin Police Retirement System</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={prn} alt="" />
                  <a href="https://www.prnpt.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Physical Rehabilitation Network</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={chestnut} alt="" />
                  <a href="https://www.chestnut.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Chestnut Health Systems</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={designHouse} alt="" />
                  <a href="https://www.designhousebystaff.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Design House by Staff</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={usElectric} alt="" />
                  <a href="https://www.usesupply.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>U.S. Electric Company</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={erfc} alt="" />
                  <a href="https://www.erfcpension.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Educational Employees' Supplementary Retirement System of Fairfax County (ERFC)</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={hfrrf} alt="" />
                  <a href="https://www.hfrrf.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Houston Firefighters Relief & Retirement Fund</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={lpf} alt="" />
                  <a href="https://www.lincolnpresidential.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>Lincoln Presidential Foundation</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={lrsretirement} alt="" />
                  <a href="https://www.lrsretirementsolutions.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>LRS Retirement Solutions</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={sangamonceo} alt="" />
                  <a href="https://www.sangamonceo.com/" className="portfolio-grid__item-link" target="_blank">
                    <span>Sangamon CEO</span>
                  </a>
                </li>
                <li className="portfolio-grid__item">
                  <img className="portfolio-grid__item-image" src={toi} alt="" />
                  <a href="https://www.toi.org/" className="portfolio-grid__item-link" target="_blank">
                    <span>The Township Officials of Illinois</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* Music */}
        <section className="section section--dark section--background-image section--background-image--fixed" id="music" style={{ backgroundImage: `url(${musicBG})` }}>
          <div
            className="section__container section__container-group section__container-group--2 section__container-group--align-center">
            <div className="section__container-item">
              <div className="section__container-item-tag"><span>Music</span></div>
              <h2 className="section__container-item-title">I used to be cool</h2>
              <p>From 2000 to 2005, I was part of a pop punk band called Yeti 242, hailing from Springfield, IL. We poured our hearts into writing catchy, melody-driven songs and prided ourselves on putting on high-energy, fun-filled live shows that brought our fans and friends together.</p>
              <p>Yeti 242 wasn't just a band—it was a family, a creative outlet, and a way to leave our mark on the local music scene. Those years were unforgettable, and the songs we wrote still hold a special place in my heart. Take a listen and relive the spirit of pop punk that defined an era for us.</p>
              <p><a href="#" className="button button--white hover-background-animate" onClick={(e) => {e.preventDefault(); openLightbox();}}>Watch Video</a></p>
            </div>
            <div className="section__container-item" style={{ textAlign: 'center' }}>
              <a href="https://open.spotify.com/album/5CEiCGgJ3XZ7bM3nwPm45A?si=0mpNaQ1ySFijkynfddTERA"
                className="section__container-item-link" target="_blank">
                <figure className="section__container-item-image-container">
                  <img className="section__container-item-image section__container-item-image--border-radius" style={{ width: '200px' , padding: '5px' , marginBottom: '0' }} src={yetiAlbum1} alt="Yeti 242 - Yetiville" />
                  <figcaption>
                    Yetiville - 2003
                  </figcaption>
                </figure>
              </a>
              <a href="https://open.spotify.com/album/1O6MCLX6XHS3hauXFSHYHA" className="section__container-item-link"
                target="_blank">
                <figure className="section__container-item-image-container">
                  <img className="section__container-item-image section__container-item-image--border-radius" style={{ width: '200px' , padding: '5px' , marginBottom: '0' }} src={yetiAlbum2} alt="Yeti 242 - Somewhere In The End" />
                  <figcaption>
                    Somewhere In The End - 2003
                  </figcaption>
                </figure>
              </a>
              <a href="https://open.spotify.com/album/3GlzClzcnFsViNVRww9DVo" className="section__container-item-link"
                target="_blank">
                <figure className="section__container-item-image-container">
                  <img className="section__container-item-image section__container-item-image--border-radius" style={{ width: '200px' , padding: '5px' , marginBottom: '0' }} src={yetiAlbum3} alt="Yeti 242 - Live at Canopy Club" />
                  <figcaption>
                    Live at Canopy Club - 2005
                  </figcaption>
                </figure>
              </a>
            </div>
          </div>
        </section>

        {/* Lightbox */}
        {isLightboxOpen && (
          <div className="lightbox">
            <div className="lightbox__overlay" onClick={closeLightbox}></div>
            <div className="lightbox__content">
              <button className="lightbox__close-button" onClick={closeLightbox}>&times;</button>
              <iframe src={videoSrc} title="Yeti 242 Video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
        )}

        {/* Podcast */}
        <section className="section" id="podcast">
          <div className="section__container section__container-group section__container-group--2 section__container-group--align-center">
            <div className="section__container-item">
              <a href="https://www.theunpluggedpodcast.com/" target="_blank">
                <img className="section__container-item-image section__container-item-image--border-radius" src={podcast} alt="Unplugged with Tyler Winters and John Pearman" />
              </a>
            </div>
            <div className="section__container-item">
              <div className="section__container-item-tag"><span>Podcast</span></div>
              <h2 className="section__container-item-title">Listen to <em>Unplugged</em></h2>
              <p>For years, my best friend and former bandmate, John Pearman, and I have been hosting <em>Unplugged</em>. What started as a way to share our love of music has turned into a weekly must-listen for fans of alternative rock, pop-punk, emo, indie, and even country.</p>
              <p>Each week, we dive into the latest music news, share exclusive interviews with some of the biggest names and brightest up-and-comers in the scene, and, of course, throw in plenty of humor and sarcasm along the way. With our shared history in music, we like to think we bring a fresh perspective—one that's as informative as it is entertaining.</p>
              <p>Whether you're here for the deep cuts or just to hang out with two best friends riffing on all things music, we've got you covered. Listen to <em><a href="https://www.theunpluggedpodcast.com/" target="_blank">Unplugged with Tyler Winters and John Pearman</a></em> wherever you listen to your favorite podcasts.</p>
              {/* <p><a href="img/resume/resume.jpg" className="button button--white button--dark-border">Download CV</a></p> */}
            </div>
          </div>
        </section>

        {/* Blog */}
        {/* {false && ( */}
          <section className="section" id="blog">
            <div className="section__container section__container-group section__container--contained">
              <div className="section__container-item">
                <div className="section__container-item-tag"><span>Blog</span></div>
                <h2 className="section__container-item-title">The blog behind the music</h2>
                <p>Since May 2016, I've been running <a href="https://allpunkedup.com" target="_blank">All Punked Up</a>, a music news website dedicated to alternative rock, pop-punk, and emo culture. Over the years, it's grown into a trusted source for the latest news, artist features, and deep dives into the scene.</p>
                <p>In 2023, the journey evolved with the launch of Unplugged, a newsletter that serves as a read-only extension of my Unplugged podcast. This new format allows me to continue sharing stories, insights, and music news in a more personal and engaging way.</p>
                <p>Check out the latest posts below!</p>
                {/* <p>Check out the latest posts below for the freshest takes on the music world and beyond!</p> */}
                {/* Loading and Error States */}
                {isLoading && (
                  <>
                    <div className="loading-screen">
                      <p>Loading posts...</p>
                      <div className="loader-circle"></div>
                    </div>
                  </>
                )}
                {error && <p className="error-message">Error fetching blog posts: {error}</p>}

                {/* Blog Posts */}
                {!isLoading && !error && (
                  <ul className="blog__list">
                    {posts.map((post, index) => (
                      <li key={index} className="blog__list-item">
                        <a href={post.link} className="blog__link" target="_blank" rel="noopener noreferrer">
                          {post.imageUrl && (
                            <img src={post.imageUrl} className="blog__image" alt={post.title || "Blog post image"} />
                          )}
                          <h3 className="blog__title">{post.title}</h3>
                          <p className="blog__date">{post.pubDate}</p>
                          <p className="blog__description">{post.description}</p>
                        </a>
                      </li>
                    ))}
                  </ul>
                )}

                {/* Fallback for No Posts */}
                {!isLoading && !error && posts.length === 0 && (
                  <p>No blog posts available at this time.</p>
                )}

                <a href="https://unplugged.allpunkedup.com/" target="_blank" className="button button--white button--dark-border">Read More</a>
              </div>
            </div>
          </section>
        {/* )} */}

        {/* Contact */}
        <section className="section" id="contact">
          <div className="section__container section__container-group section__container--contained">
            <div className="section__container-item">
              <div className="section__container-item-tag"><span>Contact</span></div>
              <h2 className="section__container-item-title">Let's talk!</h2>
              <p>I'd love to hear from you! Whether you have a question about my work, want to collaborate on a project, or just want to say hello, feel free to reach out. Use the contact form below to send me a message, and I'll get back to you as soon as I can. Let's create something amazing together!</p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLScelkBPYje30RHPyZ9EvmaV3gSlsFoqQw25tjB81OMLbrzglw/viewform" target="_blank" className="button button--white button--dark-border">Contact Me</a>
            </div>
          </div>
        </section>

      </main>

    </div>
  );
}

export default App;